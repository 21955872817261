import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, UpdateData, UserBody } from '../../auth/authentication.service';
import { NotifyService } from '../../common/notify.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
})
export class UpdateComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  errorMessage = '';
  isLoading = false;
  user: UpdateData = {
    email: '',
    name: '',
  };
  oldUser: UserBody;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((user) => {
      this.setUser(user);
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    this.authService.update(this.user).subscribe({
      next: (user) => {
        this.isLoading = false;
        if (this.user.name !== this.oldUser.name) {
          this.notify.success('Name has been changed.');
        }
        if (this.user.email !== this.oldUser.email) {
          this.notify.success(
            'Email has been changed. Check your mailbox and click  <br> confirmation link a the new email address.',
          );
        }

        this.setUser(user);
      },
      error: (data) => {
        this.isLoading = false;
        this.errorMessage = data.message;
        this.setUser(this.oldUser);
      },
    });
  }

  setUser(user: UserBody) {
    this.user.email = user.email;
    this.user.name = user.name;
    this.oldUser = user;
  }
}
