<h1 class="page-title">Welcome to Integrate.io</h1>

<div class="form-container full-width">
  <form role="form" class="form">
    <app-logo></app-logo>

    <div class="form-group">
      <h2 class="form-title">Thank you for signing up for Integrate.io</h2>

      <div class="form-subtitle">
        Please check your inbox for an email from Integrate.io that includes an activation link for your account.
      </div>
    </div>
  </form>
</div>
