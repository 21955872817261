import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class MainComponentGuard {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  public canActivateRoute(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isPingURL = window.location.href.includes('ping');
    const isAuthenticated = this.authService.isAuthenticated();

    if (isPingURL) {
      return true;
    }

    if (!isAuthenticated) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    if (this.authService.user) {
      return !!this.authService.user;
    }

    return this.authService.getUser().pipe(
      map((user) => !!user),
      catchError(() => {
        this.router.navigate(['/auth/login']);
        return new Observable<boolean>((subscriber) => {
          subscriber.next(false);
          subscriber.complete();
        });
      }),
    );
  }
}

export const canActivateMainFn: CanActivateFn = () => {
  return inject(MainComponentGuard).canActivateRoute();
};
