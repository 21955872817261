export enum InviteQueryParam {
  lowcodeetl = 'lowcodeetl',
  eltcdc = 'eltcdc',
  apigeneration = 'apigeneration',
}

export enum AppIdQueryParam {
  etl = 'etl',
  elt_cdc = 'elt_cdc',
  api_generation = 'api_generation',
}

export enum ToolTypeQueryParam {
  ETL = 'ETL',
  CDC = 'CDC',
  DF = 'DF',
}

export enum ProductQueryParam {
  Xplenty = 'Xplenty',
  FlyData = 'FlyData',
  Dreamfactory = 'Dreamfactory',
  xplenty = 'xplenty',
  flyData = 'flyData',
  dreamfactory = 'dreamfactory',
}

export enum FormTypeId {
  etl_signup_form = 'etl_signup_form',
  cdc_signup_form = 'cdc_signup_form',
  df_signup_form = 'df_signup_form',
}
