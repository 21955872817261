<h1 class="page-title">Welcome to Integrate.io</h1>

<div class="form-container column full-width">
  <app-logo></app-logo>
  <div class="form-container-inner">
    <form role="form" id="signup-form-integrateio" name="signInForm" #form="ngForm" class="form">
      <input type="hidden" name="formId" [attr.value]="formTypeId" />

      <div class="form-group title">
        <h2 class="form-title">Sign Up</h2>
      </div>

      <div class="form-group">
        <label>Full name</label>
        <input
          class="form-control"
          [(ngModel)]="credentials.name"
          placeholder="Full name"
          name="name"
          type="text"
          maxlength="254"
          autofocus
          required
        />
        @if (
          form.controls['name'] &&
          form.controls['name'].invalid &&
          (form.controls['name'].dirty || form.controls['name'].touched)
        ) {
          <div class="alert">
            @if (form.controls['name'].errors && form.controls['name'].errors['required']) {
              <div class="error-text">Please enter a name.</div>
            }
          </div>
        }
      </div>
      <div class="form-group">
        <label>Email address</label>
        <input
          class="form-control"
          [(ngModel)]="credentials.email"
          placeholder="email@example.com"
          name="email"
          type="email"
          maxlength="254"
          autofocus
          required
          email
        />
        @if (
          form.controls['email'] &&
          form.controls['email'].invalid &&
          (form.controls['email'].dirty || form.controls['email'].touched)
        ) {
          <div class="alert">
            @if (form.controls['email'].errors && form.controls['email'].errors['required']) {
              <div class="error-text">Please enter an email.</div>
            }
            @if (form.controls['email'].errors && form.controls['email'].errors['email']) {
              <div class="error-text">Please enter a valid email.</div>
            }
          </div>
        }
      </div>
      <div class="form-group">
        <label>Password</label>
        <input
          class="form-control"
          [(ngModel)]="credentials.password"
          placeholder="********"
          name="password"
          type="password"
          minlength="12"
          [pattern]="passwordPattern"
          required
        />
        @if (
          form.controls['password'] &&
          form.controls['password'].invalid &&
          (form.controls['password'].dirty || form.controls['password'].touched)
        ) {
          <div class="alert">
            @if (form.controls['password'].errors && form.controls['password'].errors['required']) {
              <div class="error-text">Please enter a password.</div>
            }
            @if (form.controls['password'].errors && form.controls['password'].errors['minlength']) {
              <div class="error-text">Password must contain at least 12 characters.</div>
            }
            @if (
              form.controls['password'].errors &&
              form.controls['password'].errors['pattern'] &&
              !form.controls['password'].errors['minlength']
            ) {
              <div class="error-text">
                Password should have at least one uppercase letter, one lowercase letter and one number.
              </div>
            }
          </div>
        }
        @if (errorMessage) {
          <div class="error-text">{{ errorMessage }}</div>
        }
      </div>
      <div class="form-group">
        <div class="bottom-text">Already have an account? <a routerLink="/auth/login" class="link">Log In.</a></div>
      </div>
      <div class="form-group">
        <button
          (click)="submit()"
          class="submit-button"
          [ngClass]="{
            invalid: !form.valid,
            loading: isLoading
          }"
        >
          {{ !isLoading ? 'Sign Up' : 'Signing Up...' }}
        </button>
      </div>
    </form>

    <div class="quote-container">
      <div class="quote-item">
        @if (product) {
          <div class="quote">
            Hello there!
            <br />
            This is the new home for your {{ product }} login. We have autofilled the signup form with your existing
            details.
            <br />
            <br />
            Simply click "Sign up", then check your email and follow the directions to start using your existing
            (migrated) {{ product }} account on the new Integrate.io platform!
          </div>
        }
        @if (!product) {
          <div class="quote with-margin">
            "Integrate.io is a state-of-the-art tool with state-of-the-art connections and is extremely flexible,
            scalable and easy to work with."
          </div>
        }
        @if (!product) {
          <div class="author-container">
            <picture>
              <source srcset="../../assets/bill.webp" type="image/webp" />
              <img src="../../assets/bill.png" class="image lazyload" alt="Bill Heffelfinger" />
            </picture>
            <div class="author">
              <div class="name">Bill Heffelfinger</div>
              <div class="position">Head Of Client Technology Solutions, Cloudfactory</div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>

  <noscript>
    <img
      height="1"
      width="1"
      style="display: none"
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=29413&fmt=gif"
    />
  </noscript>
</div>
