import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, SignupCredentials } from '../../authentication.service';
import { environment } from '../../../../environments/environment';
import { FormTypeId, InviteQueryParam, ProductQueryParam, ToolTypeQueryParam } from '../../../common/query-params';
import { PASSWORD_PATTERN } from '../../../password-policy';

function isNotCorrectProduct(productParam: string): boolean {
  return !Object.keys(ProductQueryParam)
    .map((item) => item.toLowerCase())
    .includes(productParam.toLowerCase());
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  errorMessage = '';
  isLoading = false;
  credentials: SignupCredentials = {
    name: '',
    email: '',
    password: '',
  };
  product: ProductQueryParam | '' = '';
  formTypeId: FormTypeId | '' = '';
  trial: InviteQueryParam;
  passwordPattern = PASSWORD_PATTERN;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.credentials.name = this.activatedRoute.snapshot.queryParams['name'];
    this.credentials.email = (this.activatedRoute.snapshot.queryParams['email'] || '').replaceAll(' ', '+');
    let productParam = this.activatedRoute.snapshot.queryParams['product'] || '';

    if (isNotCorrectProduct(productParam)) {
      productParam = '';
    }

    this.product = `${productParam.charAt(0).toUpperCase()}${productParam.slice(1)}` as ProductQueryParam;
    const toolType: ToolTypeQueryParam = this.activatedRoute.snapshot.queryParams['tool'] || '';

    switch (toolType) {
      case ToolTypeQueryParam.ETL:
        this.formTypeId = FormTypeId.etl_signup_form;
        this.trial = InviteQueryParam.lowcodeetl;
        break;
      case ToolTypeQueryParam.CDC:
        this.formTypeId = FormTypeId.cdc_signup_form;
        this.trial = InviteQueryParam.eltcdc;
        break;
      case ToolTypeQueryParam.DF:
        this.formTypeId = FormTypeId.df_signup_form;
        this.trial = InviteQueryParam.apigeneration;
        break;
      default:
        this.formTypeId = FormTypeId.etl_signup_form;
        break;
    }
  }

  submit() {
    const form = document.querySelector('#signup-form-integrateio') as HTMLFormElement;

    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;

    fetch(environment.HUBSPOT_URL, {
      method: 'POST',
      body: new URLSearchParams(new FormData(form) as any),
      mode: 'no-cors',
    }).then(() => {
      const body: SignupCredentials = {
        ...this.credentials,
      };

      if (this.trial) {
        body.trial = this.trial;
      }

      this.authService.signUp(body).subscribe({
        next: () => {
          this.isLoading = false;
          this.router.navigate(['/auth/thank-you']);
        },
        error: (data) => {
          this.isLoading = false;
          this.errorMessage = data.error.message;
        },
      });
    });
  }
}
