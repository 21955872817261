import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthComponentGuard {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticated = this.authService.isAuthenticated();

    if (!isAuthenticated) {
      return true;
    }

    return this.authService.getUser().pipe(
      map((user) => {
        if (user) {
          this.router.navigate(['/']);
        }
        return !user;
      }),
    );
  }
}

export const canActivateAuthenticationFn: CanActivateFn = () => {
  return inject(AuthComponentGuard).canActivateChild();
};
