import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../../authentication.service';
import { NotifyService } from '../../../common/notify.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  errorMessage = '';
  isLoading = false;
  credentials = {
    password: '',
    confirmation_token: '',
  };
  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.credentials.confirmation_token = this.activatedRoute.snapshot.queryParams['confirmation_token'];
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;

    this.authService.newEmailConfirmation(this.credentials).subscribe({
      next: () => {
        this.isLoading = false;
        this.notify.success('Your email has been changed. You can now log in.');
        this.router.navigate(['/auth/login']);
      },
      error: (data) => {
        this.isLoading = false;
        this.errorMessage = data.error.message;
      },
    });
  }
}
