import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, PasswordChangeSetData } from '../../authentication.service';
import { NotifyService } from '../../../common/notify.service';
import { InviteQueryParam } from '../../../common/query-params';
import { environment } from '../../../../environments/environment';
import { PASSWORD_PATTERN } from '../../../password-policy';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
})
export class PasswordChangeComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  errorMessage = '';
  isLoading = false;
  credentials = {
    password: '',
    reset_password_token: '',
    confirmation_token: '',
  };
  isPasswordChange = false;
  inviteProduct: InviteQueryParam;
  hasTrialParam = false;
  passwordPattern = PASSWORD_PATTERN;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: NotifyService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.credentials.reset_password_token = this.route.snapshot.queryParams['reset_password_token'];
    this.credentials.confirmation_token = this.route.snapshot.queryParams['confirmation_token'];
    this.inviteProduct = this.route.snapshot.queryParams['invite'] || this.route.snapshot.queryParams['trial'];
    this.hasTrialParam = this.route.snapshot.queryParams['trial'];

    this.isPasswordChange = !!this.credentials.reset_password_token;

    if (!this.isPasswordChange) {
      this.addTracker();
    }

    if (this.hasTrialParam) {
      this.submit();
    }

    if (!this.inviteProduct && !this.isPasswordChange) {
      this.submit();
    }
  }

  submit() {
    if (this.form && !this.form.valid) {
      return;
    }
    this.isLoading = true;
    if (this.credentials.reset_password_token) {
      this.authService.changePassword(this.credentials).subscribe({
        next: () => {
          this.isLoading = false;
          this.notify.success('You have successfully reset your password. You can now log in.');
          this.router.navigate(['/auth/login']);
        },
        error: (data) => {
          this.isLoading = false;
          this.errorMessage = data.error.message;
        },
      });
    }
    if (this.credentials.confirmation_token) {
      const body: PasswordChangeSetData = {
        confirmation_token: this.credentials.confirmation_token,
      };

      if (this.inviteProduct) {
        body.password = this.credentials.password;
      }

      this.authService.confirm(body).subscribe({
        next: ({ email }) => {
          let promise = new Promise((resolve) => resolve({}));
          if (this.hasTrialParam && this.route.snapshot.queryParams['trial'] === 'eltcdc') {
            promise = this.http.post(environment.HUBSPOT_CONFIRMATION_WEBHOOK_URL, { email }).toPromise();
          }

          promise.then(
            () => {
              this.isLoading = false;
              this.notify.success('Successfully confirmed the account.');
              this.router.navigate(['/apps'], {
                queryParams: {
                  invite: this.inviteProduct,
                },
              });
            },
            () => {
              this.isLoading = false;
              this.notify.success('Successfully confirmed the account.');
              this.router.navigate(['/apps'], {
                queryParams: {
                  invite: this.inviteProduct,
                },
              });
            },
          );
        },
        error: (data) => {
          this.isLoading = false;
          if (data?.error?.message) {
            this.errorMessage = data.error.message;
            this.notify.error(data.error.message, 'Error');
            if (data.status === 404) {
              this.router.navigate(['/auth/login']);
            }
          }
        },
      });
    }
  }

  addTracker() {
    const script = this.renderer.createElement('script');
    script.type = `text/javascript`;
    script.text = `
            window._linkedin_partner_id = '29413';

    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];

    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    (function (l) {
      if (!l) {
        window.lintrk = function (a, b) {
          window.lintrk.q.push([a, b]);
        };

        window.lintrk.q = [];
      }

      var s = document.getElementsByTagName('script')[0];

      var b = document.createElement('script');

      b.type = 'text/javascript';
      b.async = true;

      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';

      s.parentNode.insertBefore(b, s);
    })(window.lintrk);

    window.lintrk('track', { conversion_id: 9244636 });
        `;

    this.renderer.appendChild(this.document.body, script);
  }
}
