import { Component } from '@angular/core';
import { SidebarService } from '../../sidebar.service';

@Component({
  selector: 'app-main',
  template: `
    <app-header></app-header>
    <app-sidebar [ngClass]="{ open: isSidebarOpen }"></app-sidebar>
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./main.component.less'],
})
export class MainComponent {
  isSidebarOpen = false;

  constructor(private sidebarService: SidebarService) {
    this.sidebarService.isSidebarOpenSubject.subscribe(() => {
      this.isSidebarOpen = !this.isSidebarOpen;
    });
  }
}
