import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifyTemplateComponent, NotifyTemplateData } from './components/notify-template.component';

enum NotifyType {
  danger = 'danger',
  success = 'success',
  info = 'info',
  warning = 'warning',
}

interface Options {
  delay?: number;
  type: NotifyType;
  mergeError?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private previousMessage = '';
  private notifyRef: any;

  constructor(private snackBar: MatSnackBar) {}

  public alert(title: string, message: string, options: Options = { delay: 3000, type: NotifyType.info }) {
    const isPreviousNotifyVisible = this.notifyRef && document.contains(this.notifyRef.$ele[0]);
    if (!isPreviousNotifyVisible) {
      this.previousMessage = '';
    }

    if (options.mergeError && isPreviousNotifyVisible) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = message;
      if (!this.previousMessage.includes(tempElement.innerText)) {
        this.notifyRef.update('message', `${this.previousMessage}<br>${message}`);
      }
      return;
    }

    this.previousMessage = message;

    const data: NotifyTemplateData = {
      title,
      message,
      iconType: options.type,
      containerClass: '',
    };

    this.snackBar.openFromComponent(NotifyTemplateComponent, {
      duration: options.delay,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: options.type,
      data,
    });
  }

  public log(message: string, title: string = ''): void {
    this.alert(title, message, { type: NotifyType.danger });
  }

  public error(message: string, title: string = ''): void {
    this.alert(title, message, { type: NotifyType.danger, delay: 7000 });
  }

  public info(message: string, title: string = '', delay = 5000): void {
    this.alert(title, message, { type: NotifyType.info, delay });
  }

  public success(message: string, title: string = '', delay = 3000): void {
    this.alert(title, message, { type: NotifyType.success, delay });
  }

  public warn(message: string, title: string = ''): void {
    this.alert(title, message, { type: NotifyType.warning, delay: 7000 });
  }
}
