<div class="form-container full-width">
  <form role="form" name="signInForm" #form="ngForm" class="form">
    <app-logo></app-logo>

    <div class="form-group">
      <h2 class="form-title">Email change confirmation</h2>

      <div class="form-subtitle">Enter your password to confirm your new email address.</div>
    </div>

    <div class="form-group">
      <label>Password</label>
      <input
        class="form-control"
        [(ngModel)]="credentials.password"
        placeholder="********"
        name="password"
        type="password"
        required
      />
      @if (
        form.controls['password'] &&
        form.controls['password'].invalid &&
        (form.controls['password'].dirty || form.controls['password'].touched)
      ) {
        <div class="alert">
          @if (form.controls['password'].errors && form.controls['password'].errors['required']) {
            <div class="error-text">Please enter a password.</div>
          }
        </div>
      }
      @if (errorMessage) {
        <div class="error-text">{{ errorMessage }}</div>
      }
    </div>
    <div class="form-group">
      <button
        (click)="submit()"
        class="submit-button"
        [ngClass]="{
          invalid: !form.valid,
          loading: isLoading
        }"
      >
        <span>{{ !isLoading ? 'Confirm email change' : 'Confirming email change...' }}</span>
      </button>
    </div>
  </form>
</div>
