import { Component } from '@angular/core';

@Component({
  selector: 'app-logo',
  template: `
    <picture>
      <img src="/assets/logo.svg" alt="Integrate.io" class="form-logo" />
    </picture>
  `,
})
export class LogoComponent {}
