import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, DeamFactoryInstance, ProductType } from '../../auth/authentication.service';
import { DFModalTemplateComponent } from './DFModalTemplate.component';
import { AppIdQueryParam, InviteQueryParam } from '../../common/query-params';

enum AppNames {
  etl = 'ETL & Reverse ETL',
  cdc = 'ELT & CDC',
  api_generation = 'API Generation',
}

@Component({
  selector: 'app-applications',
  template: `
    @if (launchingProductName) {
      <div class="loading-screen">
        <app-spinning-wheel></app-spinning-wheel>
        <h2 class="loading-title">Logging into {{ launchingProductName }}...</h2>
      </div>
    }
    <h1 class="title">
      The complete data pipeline platform for your entire data journey.<br />
      <strong>Start Your Journey.</strong>
    </h1>
    <div class="cards-container">
      @if (errorMessage) {
        <div class="error-text">{{ errorMessage }}</div>
      }

      <div class="card">
        <img class="icon" src="./assets/etl.svg" alt="ETL & Reverse ETL" />
        <h5 class="card-title">{{ AppNames.etl }}</h5>
        <p class="card-description">
          ETL & Reverse ETL with 220+ data transformations to prepare your data for insights and reporting.
        </p>
        <button class="go-to-app-button" (click)="launch(ProductType.xplenty)">Go</button>
      </div>
      <div class="card">
        <img class="icon" src="./assets/elt_cdc.svg" alt="ELT & CDC" />
        <h5 class="card-title">{{ AppNames.cdc }}</h5>
        <p class="card-description">
          Replicate data to your warehouses giving you real-time access to all of your critical data.
        </p>
        <button class="go-to-app-button" (click)="launch(ProductType.flydata)">Go</button>
      </div>
    </div>
  `,
  styleUrls: ['./applications.component.less'],
})
export class ApplicationsComponent implements OnInit {
  errorMessage = '';
  errorMessageDF = '';
  showCreateInstance = true;
  instances: DeamFactoryInstance[];
  ProductType = ProductType;
  launchingProductName = '';
  AppNames = AppNames;

  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    const appID: AppIdQueryParam = this.activatedRoute.snapshot.queryParams['app_id'];
    const inviteProduct: InviteQueryParam = this.activatedRoute.snapshot.queryParams['invite'];

    if (appID || inviteProduct) {
      if (appID === AppIdQueryParam.etl || inviteProduct === InviteQueryParam.lowcodeetl) {
        this.launchingProductName = AppNames.etl;
        this.launch(ProductType.xplenty);
      }

      if (appID === AppIdQueryParam.elt_cdc || inviteProduct === InviteQueryParam.eltcdc) {
        this.launchingProductName = AppNames.cdc;
        this.launch(ProductType.flydata);
      }

      if (appID === AppIdQueryParam.api_generation || inviteProduct === InviteQueryParam.apigeneration) {
        // this.openDFDialog();
        this.openDFSignupPage();
      }
    }

    this.instances = this.authService.user.dreamfactory_instances;
    this.showCreateInstance = (this.instances || []).length === 0;
  }

  launch(product: ProductType, instanceName?: string) {
    this.authService.launch(product, instanceName).subscribe({
      next: () => null,
      error: () => null,
    });
  }

  openDFDialog() {
    this.dialog.open(DFModalTemplateComponent, {
      position: {
        top: '150px',
      },
      maxWidth: '500px',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  openDFSignupPage() {
    window.location = 'https://genie.dreamfactory.com/register/' as any;
  }
}
