import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { CancelComponent } from './components/cancel/cancel.component';
import { SignupComponent } from './components/signup/signup.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CommonModule } from '../common/common.module';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { AuthComponent } from './auth.component';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ConfirmationComponent,
    ResetPasswordComponent,
    PasswordChangeComponent,
    CancelComponent,
    ThankYouComponent,
    AuthComponent,
  ],
  imports: [AngularCommonModule, CommonModule, FormsModule, RouterModule],
})
export class AuthModule {}
