import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../authentication.service';
import { NotifyService } from '../../../common/notify.service';

@Component({
  selector: 'app-cancel',
  template: ` <app-spinning-wheel></app-spinning-wheel> `,
})
export class CancelComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notify: NotifyService,
  ) {}

  ngOnInit(): void {
    this.authService.cancel(this.activatedRoute.snapshot.queryParams['confirmation_token'] || '').subscribe({
      next: () => {
        this.notify.success('Email change has been cancelled. You can now log in.');
        this.router.navigate(['auth/login']);
      },
      error: () => {
        this.notify.error('There was an error with a request, confirmation token is probably invalid.');
        this.router.navigate(['auth/login']);
      },
    });
  }
}
