import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '../../../sidebar.service';
import { AuthenticationService } from '../../../auth/authentication.service';

@Component({
  selector: 'app-sidebar',
  template: `
    <icons-sprite class="hidden"></icons-sprite>
    <div id="sidebar" class="sidebar">
      <div class="sidebar-main">
        <ul class="list-unstyled menu-top">
          <li>
            <a href="https://dashboard.integrate.io/">
              <img class="logo-img" width="48" height="48" src="assets/integrate-logo.svg" alt="Integrate.io" />
            </a>
          </li>
          <li [routerLink]="['apps']" routerLinkActive="active" (click)="handleClick()" style="margin-top: 50px">
            <a matTooltipPosition="right" matTooltipClass="hide-arrow" matTooltip="Applications">
              <svg class="icon">
                <use href="#icon-apps"></use>
              </svg>
              <span class="item-desc">Applications</span>
            </a>
          </li>
          <li [routerLink]="['settings']" routerLinkActive="active" (click)="handleClick()">
            <a matTooltipPosition="right" matTooltipClass="hide-arrow" matTooltip="Settings">
              <svg class="icon">
                <use href="#icon-settings"></use>
              </svg>
              <span class="item-desc">Settings</span>
            </a>
          </li>

          <li (click)="logout()">
            <a matTooltipPosition="right" matTooltipClass="hide-arrow" matTooltip="Settings">
              <span class="item-desc">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  `,
  styleUrls: ['./sidebar.component.less'],
})
export class SidebarComponent {
  constructor(
    private sidebarService: SidebarService,
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  handleClick() {
    this.sidebarService.toggleSidebar();
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/auth/login']);
    });
  }
}
