<form role="form" name="updateForm" #form="ngForm" class="form settings">
  <div class="form-group">
    <h2 class="form-title">Update Password</h2>
  </div>

  <div class="form-group">
    <label>Current Password</label>
    <input
      class="form-control"
      [(ngModel)]="user.current_password"
      placeholder="Password"
      name="current_password"
      type="password"
      autocomplete="off"
      required
    />
    @if (
      form.controls['current_password'] &&
      form.controls['current_password'].invalid &&
      (form.controls['current_password'].dirty || form.controls['current_password'].touched)
    ) {
      <div class="alert">
        @if (form.controls['current_password'].errors && form.controls['current_password'].errors['required']) {
          <div class="error-text">Please enter a current password.</div>
        }
      </div>
    }
  </div>
  <div class="form-group">
    <label>New Password</label>
    <input
      class="form-control"
      [(ngModel)]="user.new_password"
      placeholder="Password"
      name="new_password"
      type="password"
      autocomplete="off"
      minlength="12"
      [pattern]="passwordPattern"
      required
    />
    @if (
      form.controls['new_password'] &&
      form.controls['new_password'].invalid &&
      (form.controls['new_password'].dirty || form.controls['new_password'].touched)
    ) {
      <div class="alert">
        @if (form.controls['new_password'].errors && form.controls['new_password'].errors['required']) {
          <div class="error-text">Please enter a new password.</div>
        }
        @if (form.controls['new_password'].errors && form.controls['new_password'].errors['minlength']) {
          <div class="error-text">Password must contain at least 12 characters.</div>
        }
        @if (
          form.controls['new_password'].errors &&
          form.controls['new_password'].errors['pattern'] &&
          !form.controls['new_password'].errors['minlength']
        ) {
          <div class="error-text">
            Password should have at least one uppercase letter, one lowercase letter and one number.
          </div>
        }
      </div>
    }
  </div>
  <div class="form-group">
    <button (click)="submit()" class="submit-button" [ngClass]="{ invalid: !form.valid, loading: isLoading }">
      {{ !isLoading ? 'Update' : 'Updating...' }}
    </button>
  </div>
</form>
