import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { CustomHttpInterceptorService } from './custom-http-interceptor.service';
import { UpdateComponent } from './components/update/update.component';
import { CommonModule } from './common/common.module';
import { AuthModule } from './auth/auth.module';
import { SettingsComponent } from './components/settings/settings.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { MFAComponent } from './components/mfa/mfa.component';
import { DFModalTemplateComponent } from './components/applications/DFModalTemplate.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    UpdateComponent,
    SettingsComponent,
    ApplicationsComponent,
    UpdatePasswordComponent,
    MFAComponent,
    DFModalTemplateComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularCommonModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    CommonModule,
    AuthModule,
    RouterModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
