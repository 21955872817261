import { NgModule } from '@angular/core';
import { CommonModule as AngularCommon } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LogoComponent } from './components/logo/logo.component';
import { SpinningWheelComponent } from './components/spinning-wheel/spinning-wheel.component';
import { NotifyTemplateComponent } from './components/notify-template.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { IconsSpriteComponent } from './components/icons-sprite.component';

@NgModule({
  declarations: [
    LogoComponent,
    SpinningWheelComponent,
    NotifyTemplateComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    IconsSpriteComponent,
  ],
  imports: [AngularCommon, FormsModule, RouterModule, MatTooltipModule],
  exports: [
    LogoComponent,
    SpinningWheelComponent,
    NotifyTemplateComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
  ],
})
export class CommonModule {}
