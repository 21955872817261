import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthenticationService } from './auth/authentication.service';
import { NotifyService } from './common/notify.service';

const UNAUTHORIZED_URL_EXCEPTIONS = ['/new_email_confirmation'];

const responseMessages: { [key: string]: { title: string; message: string } } = {
  '400': {
    title: 'Bad request',
    message: 'Oops! It seems like the server cannot process your request.',
  },
  '401': {
    title: 'Access denied',
    message: "Seems like you don't have the required permissions to access this page.",
  },
  '402': {
    title: 'Payment required',
    message: 'You must confirm your billing info to use this API.',
  },
  '403': {
    title: 'Forbidden',
    message: 'Sorry, you are not allowed to perform this action.',
  },
  '404': {
    title: 'Page not found',
    message: 'This is probably not the place you were looking for.',
  },
  '406': {
    title: 'Error',
    message: 'Sorry, we were unable to perform this action.',
  },
  '415': {
    title: 'Error',
    message: 'Sorry, we were unable to perform this action.',
  },
  '422': {
    title: 'Error',
    message: 'Sorry, we were unable to perform this action.',
  },
  '429': {
    title: 'Too many requests',
    message: 'Oops, the request exceeded the rate limitations. Try again in a few minutes.',
  },
  '500': {
    title: 'Internal server error',
    message: 'Oops, an internal error occurred in the request. Try again in a few minutes.',
  },
  '502': {
    title: 'Bad gateway',
    message: 'Oops, seems like the Integrate.io servers are up, but overloaded with requests. Try again later.',
  },
  '503': {
    title: 'Service unavailable',
    message: 'Oops, seems like the Integrate.io servers are up, but overloaded with requests. Try again later.',
  },
  timeout: {
    title: 'Timeout',
    message: 'Timeout was reached',
  },
};

@Injectable({
  providedIn: 'root',
})
export class CustomHttpInterceptorService {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notifyService: NotifyService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // eslint-disable-next-line no-param-reassign
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/vnd.xplenty+json; version=2',
        Authorization: `Bearer ${this.authService.getToken()}`,
      },
    });

    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              if (UNAUTHORIZED_URL_EXCEPTIONS.find((url) => err.url.includes(url))) {
                break;
              } else {
                this.authService.logout();
                this.authService.removeToken();
                this.router.navigate(['/auth/login']);
                break;
              }
            case -1: {
              const errorMessage = responseMessages['timeout'];
              this.notifyService.error(errorMessage.message, errorMessage.title);
              break;
            }
            default: {
              const errorMessage = responseMessages[String(err.status)];
              if (err.url.includes('oauth/token')) {
                errorMessage.message = 'There was a problem with your login.';
              }
              if (errorMessage) {
                this.notifyService.error(errorMessage.message, errorMessage.title);
              }
              break;
            }
          }
        }
        return new Observable<HttpEvent<any>>((subscriber) => subscriber.error(err));
      }),
    );
  }
}
