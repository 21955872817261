import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { InviteQueryParam } from '../common/query-params';

const API_URL = environment.api_url;
const ACCESS_TOKEN_KEY = 'access_token';

export interface Credentials {
  email: string;
  password: string;
}

export interface LoginPayload {
  access_token: string;
  mfa_required?: boolean;
}

export interface SignupCredentials {
  name: string;
  email: string;
  password: string;
  trial?: InviteQueryParam;
}

interface LaunchResponse {
  url?: string;
  integrateio_id: string;
  email: string;
  name: string;
  token: string;
  timestamp: string;
}

export interface UpdateData {
  new_password?: string;
  current_password?: string;
  name?: string;
  email?: string;
}

export interface ResetPasswordData {
  email: string;
}

export interface PasswordChangeSetData {
  password?: string;
  reset_password_token?: string;
  confirmation_token?: string;
}

export interface DeamFactoryInstance {
  created_at: string;
  id: number;
  name: string;
  status: string;
  updated_at: string;
}

export interface UserBody {
  id: string;
  name: string;
  email: string;
  confirmed_at: string;
  created_at: string;
  updated_at: string;
  confirmed: string;
  mfa_enabled: boolean;
  dreamfactory_instances: DeamFactoryInstance[];
}

export enum ProductType {
  xplenty = 'xplenty',
  flydata = 'flydata',
  dreamfactory = 'dreamfactory',
}

export interface ConfirmationPayload {
  access_token: string;
  created_at: number;
  email: string;
  expires_in: number;
  token_type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userItem: UserBody;
  private userItem$ = new Subject<UserBody>();

  constructor(private httpClient: HttpClient) {}

  private setUser(user: UserBody) {
    this.userItem = user;
    this.userItem$.next(user);
  }

  public get user(): UserBody {
    return this.userItem;
  }

  public get user$(): Observable<UserBody> {
    return this.userItem$.asObservable();
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  // eslint-disable-next-line class-methods-use-this
  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  // eslint-disable-next-line class-methods-use-this
  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  login(credentials: Credentials): Observable<LoginPayload> {
    return this.httpClient.post<LoginPayload>(`${API_URL}oauth/token`, { ...credentials, grant_type: 'password' }).pipe(
      tap((response: LoginPayload) => {
        localStorage.setItem(ACCESS_TOKEN_KEY, response.access_token);
      }),
    );
  }

  signUp(credentials: SignupCredentials): Observable<null> {
    return this.httpClient.post<null>(`${API_URL}auth/signup`, {
      user: {
        name: credentials.name,
        email: credentials.email,
        password: credentials.password,
      },
      trial: credentials.trial,
    });
  }

  logout(): Observable<null> {
    this.setUser(null);
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    return this.httpClient.post<null>(`${API_URL}oauth/revoke`, { token }).pipe(
      tap(() => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
      }),
    );
  }

  getUser(): Observable<UserBody> {
    return this.httpClient
      .get<{ user: UserBody }>(`${API_URL}api/user`)
      .pipe(map((response) => response.user))
      .pipe(
        tap((user) => {
          this.setUser(user);
        }),
      );
  }

  update(body: UpdateData): Observable<UserBody> {
    return this.httpClient
      .put<{ user: UserBody }>(`${API_URL}api/user`, body)
      .pipe(map((response) => (response ? response.user : ({} as UserBody))));
  }

  resetPassword(body: ResetPasswordData): Observable<null> {
    return this.httpClient.post<null>(`${API_URL}api/user/password`, body);
  }

  changePassword(body: PasswordChangeSetData): Observable<null> {
    return this.httpClient.put<null>(`${API_URL}api/user/password`, body);
  }

  confirm(body: PasswordChangeSetData): Observable<ConfirmationPayload> {
    return this.httpClient.put<ConfirmationPayload>(`${API_URL}api/user/confirmation`, body).pipe(
      tap((response: ConfirmationPayload) => {
        localStorage.setItem(ACCESS_TOKEN_KEY, response.access_token);
      }),
    );
  }

  newEmailConfirmation(body: PasswordChangeSetData): Observable<null> {
    return this.httpClient.post<null>(`${API_URL}/api/user/new_email_confirmation`, body);
  }

  cancel(confirmation_token: string): Observable<null> {
    return this.httpClient.put<null>(`${API_URL}/api/user/confirmation/cancel`, { confirmation_token });
  }

  static redirectWithPost(body: LaunchResponse, url: string): Observable<null> {
    const fields: Array<keyof LaunchResponse> = ['integrateio_id', 'email', 'name', 'token', 'timestamp'];

    const form = document.createElement('form');
    form.style.display = 'none';
    form.method = 'POST';
    form.action = url;

    fields.forEach((field) => {
      const fieldInput = document.createElement('input');
      fieldInput.type = 'hidden';
      fieldInput.name = field;
      fieldInput.value = body[field];
      form.appendChild(fieldInput);
      return null;
    });

    document.body.appendChild(form);
    form.submit();
    return null;
  }

  launch(product: ProductType, instance?: string): Observable<LaunchResponse> {
    return this.httpClient
      .post<LaunchResponse>(`${API_URL}api/launch`, { product, instance })
      .pipe(
        switchMap(({ integrateio_id, email, name, token, timestamp, url }: LaunchResponse) =>
          AuthenticationService.redirectWithPost({ integrateio_id, email, name, token, timestamp }, url),
        ),
      );
  }

  DFProvisionInstance(): Observable<{ message: string; status: number }> {
    return this.httpClient.post<{ message: string; status: number }>(`${API_URL}api/provision/df`, {});
  }
}
