import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, UpdateData } from '../../auth/authentication.service';
import { NotifyService } from '../../common/notify.service';
import { PASSWORD_PATTERN } from '../../password-policy';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
})
export class UpdatePasswordComponent {
  @ViewChild('form') form: NgForm;

  errorMessage = '';
  isLoading = false;
  user: UpdateData = {
    new_password: '',
    current_password: '',
  };
  passwordPattern = PASSWORD_PATTERN;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: NotifyService,
  ) {}

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    this.authService.update(this.user).subscribe({
      next: () => {
        this.isLoading = false;
        this.notify.success('Password has been changed. You can now log in.');
        this.authService.removeToken();
        this.router.navigate(['/auth/login']);
      },
      error: (data) => {
        this.isLoading = false;
        this.notify.error(data.error.message);
      },
    });
  }
}
