import { Component, OnInit } from '@angular/core';
import { CanonicalService } from './canonical.service';
import { SegmentService } from './segment.service';
import { environment } from '../environments/environment';
import { AuthenticationService } from './auth/authentication.service';
import { filter, first } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-root',
  template: `
    <div class="content" role="main">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    private canonicalService: CanonicalService,
    private segmentService: SegmentService,
    private authService: AuthenticationService,
  ) {}
  title = 'integrate-io-dashboard';

  ngOnInit() {
    const isPasswordResetURL =
      window.location.href.includes('auth/forgot/reset') || window.location.href.includes('confirmation_token');
    const isPingURL = window.location.href.includes('ping');

    if (!isPasswordResetURL && !isPingURL) {
      this.segmentService.init(environment.SEGMENT_KEY);
    }

    this.canonicalService.setCanonicalURL();

    this.authService.user$.pipe(filter(Boolean), first()).subscribe((user) => {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.name,
      });
    });
  }
}
