<h1 class="page-title">Welcome to Integrate.io</h1>

<div class="form-container full-width">
  <form role="form" name="signInForm" #form="ngForm" class="form">
    <app-logo></app-logo>

    @if ((inviteProduct || isPasswordChange) && !hasTrialParam) {
      <div class="form-group">
        <h2 class="form-title">{{ isPasswordChange ? 'Password reset' : 'Welcome' }}</h2>
        <div class="form-subtitle">
          {{
            isPasswordChange
              ? 'To reset your password, enter the new password you want to use to sign in to Integrate.io.'
              : 'To setup your account, please enter a password. Password must contain at least 12 characters.'
          }}
        </div>
      </div>
    }
    @if ((inviteProduct || isPasswordChange) && !hasTrialParam) {
      <div class="form-group">
        <label>Password</label>
        <input
          class="form-control"
          [(ngModel)]="credentials.password"
          placeholder="********"
          name="password"
          type="password"
          minlength="12"
          [pattern]="passwordPattern"
          required
        />
        @if (
          form.controls['password'] &&
          form.controls['password'].invalid &&
          (form.controls['password'].dirty || form.controls['password'].touched)
        ) {
          <div class="alert">
            @if (form.controls['password'].errors && form.controls['password'].errors['required']) {
              <div class="error-text">Please enter a password.</div>
            }
            @if (form.controls['password'].errors && form.controls['password'].errors['minlength']) {
              <div class="error-text">Password must contain at least 12 characters.</div>
            }
            @if (
              form.controls['password'].errors &&
              form.controls['password'].errors['pattern'] &&
              !form.controls['password'].errors['minlength']
            ) {
              <div class="error-text">
                Password should have at least one uppercase letter, one lowercase letter and one number.
              </div>
            }
          </div>
        }
        @if (errorMessage) {
          <div class="error-text">{{ errorMessage }}</div>
        }
      </div>
    }
    @if ((inviteProduct || isPasswordChange) && !hasTrialParam) {
      <div class="form-group">
        <button
          (click)="submit()"
          class="submit-button"
          [ngClass]="{
            invalid: !form.valid,
            loading: isLoading
          }"
        >
          @if (isPasswordChange) {
            <span>{{ !isLoading ? 'Reset my password' : 'Resetting my password...' }}</span>
          }
          @if (!isPasswordChange) {
            <span>{{ !isLoading ? 'Create User' : 'Creating User...' }}</span>
          }
        </button>
      </div>
    }
    @if (isPasswordChange) {
      <div class="form-group">
        <div class="bottom-text">Have an account? <a routerLink="/auth/login" class="link">Sign In.</a></div>
      </div>
    }
  </form>
</div>
