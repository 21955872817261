import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <footer class="footer">
      <p class="footer-text">
        <a href="https://www.integrate.io/privacy/" target="_blank">Cookie policy</a>
        <span class="separator">|</span>
        <a href="https://www.integrate.io/tos/" target="_blank">Terms of Use</a>
        <span class="separator">|</span>
        ©2024 Integrate.io All rights reserved
      </p>
    </footer>
  `,
})
export class FooterComponent {}
