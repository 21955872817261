import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService, DeamFactoryInstance, ProductType } from '../../auth/authentication.service';
import { NotifyService } from '../../common/notify.service';

@Component({
  selector: 'integrate-io-template',
  template: `
    <div class="df-dialog">
      <img class="image" src="./assets/dreamfactory-logo-gray.png" alt="Integrate.io" />
      <h2 class="title">Welcome to Dreamfactory</h2>
      @if (showCreateInstance) {
        <div class="description">
          Your account is not associated with a Dreamfactory instance. Click the button to create a new instance.
        </div>
      }
      @if (!showCreateInstance) {
        <div class="description">Select an instance to log into.</div>
      }
      @if (!showCreateInstance) {
        <ul class="instances-list">
          @for (item of instances; track item) {
            <li class="instance-item" (click)="openInstance(item)">
              <div class="first-letter">{{ item.name[0] }}</div>
              {{ item.name }}
              <div class="go-text">Go!</div>
            </li>
          }
        </ul>
      }
      @if (showCreateInstance) {
        <button type="button" class="create-instance-button" (click)="createInstance()">+ Create instance</button>
      }
      <div class="dialog-close" (click)="cancel()"><i class="fa fa-times"></i></div>
      @if (errorMessage) {
        <div class="error-message">{{ errorMessage }}</div>
      }
    </div>
  `,
})
export class DFModalTemplateComponent implements OnInit {
  errorMessage = '';
  showCreateInstance = true;
  instances: DeamFactoryInstance[];

  constructor(
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<DFModalTemplateComponent>,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.instances = this.authService.user.dreamfactory_instances;
    this.showCreateInstance = this.instances.length === 0;
  }

  createInstance() {
    this.authService.DFProvisionInstance().subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.notify.success(res.message);
        }
        if (res.status === 400) {
          this.errorMessage = res.message;
        }
      },
      error: (res) => {
        this.errorMessage = res.error?.message;
      },
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  openInstance(item: DeamFactoryInstance) {
    this.authService.launch(ProductType.dreamfactory, item.name).subscribe({
      next: () => null,
      error: () => null,
    });
  }
}
