import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../../authentication.service';
import { SegmentService } from '../../../segment.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  @ViewChild('form') form: NgForm;

  errorMessage = '';
  isLoading = false;
  credentials = {
    email: '',
    password: '',
    mfa_code: '',
  };
  isMFA = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private segmentService: SegmentService,
  ) {
    if (this.segmentService.analytics) {
      this.segmentService.analytics.load(environment.SEGMENT_KEY, { integrations: { HubSpot: false } });
    }
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    if (!this.isMFA) {
      delete this.credentials.mfa_code;
    }
    this.authService.login(this.credentials).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response && response.mfa_required) {
          this.isMFA = true;
          return;
        }
        this.router.navigate(['/']);
      },
      error: (data) => {
        this.isLoading = false;
        this.credentials.mfa_code = '';
        if (this.isMFA) {
          this.errorMessage = 'Invalid token. Please try again.';
        } else {
          this.errorMessage = data.error.message || 'There was a problem with your login.';
        }
        this.isMFA = false;
      },
    });
  }
}
