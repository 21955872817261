import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  public isSidebarOpenSubject = new Subject();

  toggleSidebar() {
    this.isSidebarOpenSubject.next(true);
  }
}
