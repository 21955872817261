import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, UserBody } from '../../../auth/authentication.service';
import { SidebarService } from '../../../sidebar.service';

@Component({
  selector: 'app-header',
  template: `
    <div class="toolbar" role="banner">
      <button
        aria-label="mobile navigation button"
        type="button"
        class="mobile-icon"
        [ngClass]="{ open: isOpen }"
        (click)="toggleMobileNavigation()"
        id="mobile-icon"
      >
        <span></span>
      </button>
      @if (user) {
        <div class="welcome-text">
          Welcome,
          {{ user.name || user.email }}
        </div>
      }
      <div class="spacer"></div>
      @if (isLoggedIn) {
        <span class="link" (click)="logout()"> Logout </span>
      }
      @if (!isLoggedIn) {
        <a routerLink="/auth/login" class="link"> Login </a>
      }
    </div>
  `,
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
  isOpen = false;
  user: UserBody;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private sidebarService: SidebarService,
  ) {
    this.sidebarService.isSidebarOpenSubject.subscribe(() => {
      this.isOpen = !this.isOpen;
    });
  }

  ngOnInit() {
    this.user = this.authService.user;
  }

  get isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  toggleMobileNavigation() {
    this.sidebarService.toggleSidebar();
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/auth/login']);
    });
  }
}
