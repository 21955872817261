import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, UpdateData } from '../../auth/authentication.service';
import { NotifyService } from '../../common/notify.service';
import { environment } from '../../../environments/environment';

const secretPlaceHolder = 'the secret key';

interface EnrollResponse {
  mfa_qr: string;
  mfa_secret: string;
}

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
})
export class MFAComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  mfaQr = '';
  mfaSecret = '';
  showMfaSecret = false;
  mfaSecretDisplay = secretPlaceHolder;
  isConfirming = false;
  isMfaEnabled = false;
  credentials = {
    current_password: '',
    mfa_code: '',
  };
  errorMessage = '';
  isLoading = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: NotifyService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((user) => {
      this.isMfaEnabled = user.mfa_enabled;
    });
  }

  enrollCallback(response: EnrollResponse) {
    this.mfaSecret = response.mfa_secret;
    this.mfaQr = response.mfa_qr;
    this.isConfirming = true;
  }

  confirmCallback(response: any) {
    this.notify.success(response.message);
    this.mfaQr = null;
    this.mfaSecret = null;
    this.isMfaEnabled = true;
    this.isConfirming = false;
    this.credentials.current_password = '';
    this.credentials.mfa_code = '';
  }

  unEnrollCallback(response: any) {
    this.notify.success(response.message);
    this.isMfaEnabled = false;
    this.isConfirming = false;
    this.credentials.current_password = '';
    this.credentials.mfa_code = '';
  }

  getActionText(): string {
    return this.isMfaEnabled ? 'Disable' : 'Enable';
  }

  getInfoText(): string {
    return this.isMfaEnabled
      ? 'Lost your authentication device? Simply disable Two-Factor authentication below, then re-enable it with a new device.'
      : 'Enable Two-Factor Authentication to give your Integrate.io account an extra layer of security.';
  }

  toggleSecret() {
    this.showMfaSecret = !this.showMfaSecret;
    this.mfaSecretDisplay = this.showMfaSecret ? this.mfaSecret : secretPlaceHolder;
  }

  toggle() {
    if (!this.form.valid) {
      return;
    }
    // if MFA is on, the user wants to remove it; if it's off, the user is turning it on
    const endpoint = this.isMfaEnabled ? '/user/mfa/remove' : '/user/mfa';
    const url = `${environment.api_url}api${endpoint}`;
    const data = this.credentials;

    const request = this.isConfirming ? this.http.put(url, data) : this.http.post(url, data);

    this.isLoading = true;

    request.subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (this.isConfirming) {
          this.confirmCallback(response);
        } else if (this.isMfaEnabled) {
          // if mfa was enabled then this is a call to disable it
          this.unEnrollCallback(response);
        } else {
          this.enrollCallback(response);
        }

        if (this.form.controls['current_password']) {
          this.form.resetForm();
        }
      },
      error: (response) => {
        this.isLoading = false;
        const errorData = response ? response.error : null;
        let errorMessage = 'Invalid credentials.';

        if (errorData && errorData.message) {
          errorMessage = errorData.message;
          this.notify.error(errorMessage);
        }
      },
    });
  }
}
