import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { PasswordChangeComponent } from './auth/components/password-change/password-change.component';
import { SignupComponent } from './auth/components/signup/signup.component';
import { CancelComponent } from './auth/components/cancel/cancel.component';
import { ConfirmationComponent } from './auth/components/confirmation/confirmation.component';
import { UpdateComponent } from './components/update/update.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { ThankYouComponent } from './auth/components/thank-you/thank-you.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { MFAComponent } from './components/mfa/mfa.component';
import { AuthComponent } from './auth/auth.component';
import { canActivateAuthenticationFn } from './auth-component.guard';
import { canActivateMainFn } from './main-component.guard';
import { PingComponent } from './PingComponent';

const routes: Routes = [
  {
    path: 'ping',
    component: PingComponent,
    data: {
      title: 'Ping!',
    },
  },
  {
    path: '',
    component: MainComponent,
    canActivateChild: [canActivateMainFn],
    children: [
      { path: 'apps', component: ApplicationsComponent },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          { path: 'profile', component: UpdateComponent },
          { path: 'password', component: UpdatePasswordComponent },
          { path: 'mfa', component: MFAComponent },
          { path: '', redirectTo: 'profile', pathMatch: 'full' },
        ],
      },
      { path: '', redirectTo: 'apps', pathMatch: 'full' },
    ],
  },
  { path: 'auth/confirmation/cancel', component: CancelComponent, canActivate: [canActivateMainFn] },
  { path: 'auth/confirmation', component: ConfirmationComponent, canActivate: [canActivateMainFn] },
  {
    path: 'auth',
    component: AuthComponent,
    canActivateChild: [canActivateAuthenticationFn],
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'thank-you', component: ThankYouComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'signup/confirmation', component: PasswordChangeComponent },
    ],
  },
  { path: 'user/update', component: UpdateComponent },
  { path: 'auth/forgot/reset', component: PasswordChangeComponent },
  { path: 'auth/forgot', component: ResetPasswordComponent },
  { path: 'signup', redirectTo: '/auth/signup' },
  { path: 'login', redirectTo: 'auth/login' },
  { path: 'update', redirectTo: 'user/update' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
