import { Component } from '@angular/core';

@Component({
  selector: 'app-settings',
  template: `
    <div class="sidebar-sub">
      <div class="sub-menu">
        <strong class="sub-menu-header">Your settings</strong>
        <ul class="list-unstyled">
          <li [routerLink]="['/settings/profile']" routerLinkActive="active">
            <a>Profile</a>
          </li>
          <li [routerLink]="['/settings/password']" routerLinkActive="active">
            <a>Password</a>
          </li>
          <li [routerLink]="['/settings/mfa']" routerLinkActive="active">
            <a>Two-factor Authentication</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="settings-item">
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./settings.component.less'],
})
export class SettingsComponent {}
