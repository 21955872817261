<h1 class="page-title">Welcome to Integrate.io</h1>

<div class="form-container full-width">
  <form role="form" name="signInForm" #form="ngForm" class="form">
    <app-logo></app-logo>

    <div class="form-group">
      <h2 class="form-title">Reset password</h2>

      <div class="form-subtitle">
        To reset your password, enter the email address you use to sign in to Integrate.io.
      </div>
    </div>

    <div class="form-group">
      <label>Email</label>
      <input
        class="form-control"
        [(ngModel)]="credentials.email"
        placeholder="Email"
        name="email"
        type="email"
        maxlength="254"
        autofocus
        required
        email
      />
      @if (
        form.controls['email'] &&
        form.controls['email'].invalid &&
        (form.controls['email'].dirty || form.controls['email'].touched)
      ) {
        <div class="alert">
          @if (form.controls['email'].errors && form.controls['email'].errors['required']) {
            <div class="error-text">Please enter an email.</div>
          }
          @if (form.controls['email'].errors && form.controls['email'].errors['email']) {
            <div class="error-text">Please enter a valid email.</div>
          }
        </div>
      }
      @if (errorMessage) {
        <div class="error-text">{{ errorMessage }}</div>
      }
    </div>
    <div class="form-group">
      <button (click)="submit()" class="submit-button" [ngClass]="{ invalid: !form.valid, loading: isLoading }">
        {{ !isLoading ? 'Reset Password' : 'Reset Password...' }}
      </button>
    </div>
    <div class="form-group">
      <div class="bottom-text">Already have an account? <a routerLink="/auth/login" class="link">Log In.</a></div>
    </div>
  </form>
</div>
