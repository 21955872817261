<h1 class="page-title">Welcome to Integrate.io</h1>

<div class="form-container full-width">
  <form role="form" name="signInForm" #form="ngForm" class="form">
    <app-logo></app-logo>

    <div class="form-group">
      <h2 class="form-title">Log In</h2>
    </div>
    @if (isMFA) {
      <div class="form-group">
        <label>Verification code</label>
        <input
          class="form-control"
          [(ngModel)]="credentials.mfa_code"
          placeholder="Verification code"
          name="mfa_code"
          type="text"
          maxlength="6"
          autofocus
          required
        />
        @if (
          form.controls['mfa_code'] &&
          form.controls['mfa_code'].invalid &&
          (form.controls['mfa_code'].dirty || form.controls['mfa_code'].touched)
        ) {
          <div class="alert">
            @if (form.controls['mfa_code'].errors && form.controls['mfa_code'].errors['required']) {
              <div class="error-text">Please enter a code.</div>
            }
          </div>
        }
      </div>
    }
    @if (!isMFA) {
      <div class="form-group">
        <label>Email address</label>
        <input
          class="form-control"
          [(ngModel)]="credentials.email"
          placeholder="email@example.com"
          name="email"
          type="email"
          maxlength="254"
          required
          email
        />
        @if (
          form.controls['email'] &&
          form.controls['email'].invalid &&
          (form.controls['email'].dirty || form.controls['email'].touched)
        ) {
          <div class="alert">
            @if (form.controls['email'].errors && form.controls['email'].errors['required']) {
              <div class="error-text">Please enter an email.</div>
            }
            @if (form.controls['email'].errors && form.controls['email'].errors['email']) {
              <div class="error-text">Please enter a valid email.</div>
            }
          </div>
        }
      </div>
    }
    @if (!isMFA) {
      <div class="form-group">
        <label>Password</label>
        <input
          class="form-control"
          [(ngModel)]="credentials.password"
          placeholder="********"
          name="password"
          type="password"
          required
        />
        @if (
          form.controls['password'] &&
          form.controls['password'].invalid &&
          (form.controls['password'].dirty || form.controls['password'].touched)
        ) {
          <div class="alert">
            @if (form.controls['password'].errors && form.controls['password'].errors['required']) {
              <div class="error-text">Please enter a password.</div>
            }
          </div>
        }
        @if (errorMessage) {
          <div class="error-text">{{ errorMessage }}</div>
        }
      </div>
    }

    <div class="form-group">
      <button (click)="submit()" class="submit-button" [ngClass]="{ invalid: !form.valid, loading: isLoading }">
        {{ !isLoading ? 'Sign In' : 'Signing In...' }}
      </button>
    </div>

    @if (!isMFA) {
      <div class="form-group">
        <div class="bottom-text">Forgot password? <a routerLink="/auth/forgot" class="link">Reset it here.</a></div>
      </div>
    }
  </form>
</div>
