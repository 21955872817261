import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, ResetPasswordData } from '../../authentication.service';
import { NotifyService } from '../../../common/notify.service';
import { environment } from '../../../../environments/environment';
import { SegmentService } from '../../../segment.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
  @ViewChild('form') form: NgForm;

  errorMessage = '';
  isLoading = false;
  credentials: ResetPasswordData = {
    email: '',
  };

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: NotifyService,
    private segmentService: SegmentService,
  ) {
    if (this.segmentService.analytics) {
      this.segmentService.analytics.load(environment.SEGMENT_KEY, { integrations: { HubSpot: false } });
    }
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    this.authService.resetPassword(this.credentials).subscribe({
      next: () => {
        this.isLoading = false;
        this.notify.success('Password reset email sent. Please check your inbox');
        this.router.navigate(['/auth/login']);
      },
      error: (data) => {
        this.isLoading = false;
        this.errorMessage = data.error.message;
      },
    });
  }
}
