<form role="form" name="updateForm" #form="ngForm" class="form settings">
  <div class="form-group">
    <h2 class="form-title">Update User Details</h2>
  </div>

  <div class="form-group">
    <label>Email</label>
    <input
      class="form-control"
      [(ngModel)]="user.email"
      placeholder="Email"
      name="email"
      type="email"
      maxlength="254"
      autocomplete="off"
      autofocus
      email
      required
    />
    @if (
      form.controls['email'] &&
      form.controls['email'].invalid &&
      (form.controls['email'].dirty || form.controls['email'].touched)
    ) {
      <div class="alert">
        @if (form.controls['email'].errors && form.controls['email'].errors['required']) {
          <div class="error-text">Please enter an email.</div>
        }
        @if (form.controls['email'].errors && form.controls['email'].errors['email']) {
          <div class="error-text">Please enter a valid email.</div>
        }
      </div>
    }
  </div>
  <div class="form-group">
    <label>Name</label>
    <input
      class="form-control"
      [(ngModel)]="user.name"
      placeholder="Name"
      name="name"
      type="text"
      maxlength="254"
      autocomplete="off"
      required
    />
    @if (
      form.controls['name'] &&
      form.controls['name'].invalid &&
      (form.controls['name'].dirty || form.controls['name'].touched)
    ) {
      <div class="alert">
        @if (form.controls['name'].errors && form.controls['name'].errors['required']) {
          <div class="error-text">Please enter a name.</div>
        }
      </div>
    }
  </div>
  <div class="form-group">
    <button (click)="submit()" class="submit-button" [ngClass]="{ invalid: !form.valid, loading: isLoading }">
      {{ !isLoading ? 'Update' : 'Updating...' }}
    </button>
  </div>
</form>
