<form role="form" name="updateForm" #form="ngForm" class="form settings wider">
  <div class="form-group">
    <h2 class="form-title">{{ getActionText() }} two-factor authentication</h2>
  </div>

  @if (isMfaEnabled) {
    <p class="alert-info">
      <i class="fa fa-lock"></i>
      Two-factor authentication is <b>enabled</b>
    </p>
  }
  @if (!mfaQr) {
    <div class="form-group">
      <label>Current Password</label>
      <input
        class="form-control"
        [(ngModel)]="credentials.current_password"
        placeholder="Password"
        name="current_password"
        type="password"
        autocomplete="off"
        required
      />
      @if (
        form.controls['current_password'] &&
        form.controls['current_password'].invalid &&
        (form.controls['current_password'].dirty || form.controls['current_password'].touched)
      ) {
        <div class="error-text">Please enter a current password.</div>
      }
      @if (errorMessage) {
        <div class="error-text">{{ errorMessage }}</div>
      }
    </div>
  }
  @if (mfaQr) {
    <div class="form-group">
      <b>1. Get the app.</b>
      <span
        >Download
        <a class="linkOpensNewTab" target="_blank" href="https://support.google.com/accounts/answer/1066447"
          >Google Authenticator</a
        >, <a class="linkOpensNewTab" target="_blank" href="https://authy.com/download/">Authy</a>, or
        <a
          class="linkOpensNewTab"
          target="_blank"
          href="https://www.salesforce.com/solutions/mobile/app-suite/security/"
          >Salesforce Authenticator</a
        >
        on your phone.</span
      >
      <hr />
      <b>2. Scan the QR code below.</b>
      <span
        >Alternatively, you can type <a (click)="toggleSecret()" style="cursor: pointer">{{ mfaSecretDisplay }}</a> into
        your authenticator app.
      </span>
      <div>
        <img [src]="mfaQr" />
      </div>
      <hr />
      <b>3. Verify your device.</b>
      Enter the code to confirm enrolment. Enabling two-factor authentication will log you out of other Integrate.io
      dashboard sessions (e.g. in other tabs or devices).
      <br />
      <br />
    </div>
  }
  @if (mfaQr) {
    <div class="form-group">
      <input
        class="form-control"
        [(ngModel)]="credentials.mfa_code"
        placeholder="Code"
        name="mfa_code"
        type="text"
        autocomplete="off"
        maxlength="6"
      />
    </div>
  }
  <div class="form-group">
    <button (click)="toggle()" class="submit-button" [ngClass]="{ invalid: !form.valid, loading: isLoading }">
      {{ !isLoading ? getActionText() + ' 2FA' : isMfaEnabled ? 'Disabling...' : 'Enabling...' }}
    </button>
  </div>
</form>
